import { ICity, IUserProfile } from '@/interfaces';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setUsers(state: AdminState, payload: IUserProfile[]) {
        state.users = payload;
    },
    setUser(state: AdminState, payload: IUserProfile) {
        const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
        users.push(payload);
        state.users = users;
    },
    setCities(state: AdminState, payload: ICity[]) {
        state.cities = payload;
    },
    setCity(state: AdminState, payload: ICity) {
        const cities = state.cities.filter((city: ICity) => city.id !== payload.id);
        cities.push(payload);
        state.cities = cities;
    },
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitSetCity = commit(mutations.setCity);
export const commitSetCities = commit(mutations.setCities);
