import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IAppearanceCreate, IAppearanceTreeNodeCreate, ICityCreate, ICityLegalTexts, ICitySettings, ICityUpdate, IHomePageRowsCreate, ITicketType, ITicketUpdate, IUserProfileCreate, IUserProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetUsers, commitSetUser, commitSetCities, commitSetCity } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { readHasAdminAccess } from '../main/getters';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
    async actionSendPushNotification(context: MainContext, payload: { cityId: number, appearanceId: number, isSystemNotification: boolean }) {
        const loadingNotification = { content: 'sending', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.sendPushNotification(context.rootState.main.token, payload.cityId, payload.appearanceId, payload.isSystemNotification),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Push-Benachrichtigung erfolgreich eingereiht', color: 'success' });

            return response.data;
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetAppearances(context: MainContext, payload: { cityId: number, subNodeId: number }) {
        try {
            const response = await api.getAppearances(payload.cityId, payload.subNodeId);
            if (response) {
                return response.data;
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateAppearances(context: MainContext, payload: { cityId: number, appearance: IAppearanceCreate, files: { name: string, file: File }[] }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createAppearance(context.rootState.main.token, payload.cityId, payload.appearance, payload.files),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Auftritt erfolgreich erstellt', color: 'success' });

            return response.data;
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateAppearances(context: MainContext, payload: { cityId: number, appearanceId: number, appearance: IAppearanceCreate, files: { name: string, file: File }[] }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateAppearance(context.rootState.main.token, payload.cityId, payload.appearanceId, payload.appearance, payload.files),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Auftritt erfolgreich aktualisiert', color: 'success' });

            return response.data;
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteAppearances(context: MainContext, payload: { cityId: number, appearanceId: number }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteAppearance(context.rootState.main.token, payload.cityId, payload.appearanceId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetCity(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Auftritt erfolgreich gelöscht', color: 'success' });
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTreeNodes(context: MainContext, cityId: number) {
        try {
            const response = await api.getTreeNodes(cityId);
            if (response) {
                return response.data;
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionSaveTreeNode(context: MainContext, payload: { cityId: number, nodes: IAppearanceTreeNodeCreate[] }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.saveTreeNode(context.rootState.main.token, payload.cityId, payload.nodes),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Ebene erfolgreich aktualisiert', color: 'success' });

            return response.data;
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetHomePage(context: MainContext, cityId: number) {
        try {
            const response = await api.getHomePage(cityId);
            if (response) {
                return response.data;
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionPostHomePage(context: MainContext, payload: { cityId: number, homePage: IHomePageRowsCreate, files: { name: string, file: File }[] }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.saveHomePage(context.rootState.main.token, payload.cityId, payload.homePage, payload.files),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Startseite erfolgreich aktualisiert', color: 'success' });

            return response.data;
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTickets(context: MainContext, payload: { cityId: number, skip: number, limit: number }) {
        try {
            const response = await api.getTickets(payload.cityId, payload.skip, payload.limit);
            if (response) {
                return response.data;
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOneTicket(context: MainContext, payload: { cityId: number, ticketId: number }) {
        try {
            const response = await api.getTicket(payload.cityId, payload.ticketId);
            if (response) {
                return response.data;
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateTicket(context: MainContext, payload: { cityId: number, ticketId: number, ticket: ITicketUpdate }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.saveTicket(context.rootState.main.token, payload.cityId, payload.ticketId, payload.ticket),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Mängelmeldung erfolgreich aktualisiert', color: 'success' });

            return response.data;
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteTicket(context: MainContext, payload: { cityId: number, ticketId: number }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteTicket(context.rootState.main.token, payload.cityId, payload.ticketId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Mängelmeldung erfolgreich gelöscht', color: 'success' });

            return response.data;
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTicketTypes(context: MainContext, cityId: number) {
        try {
            const response = await api.getTicketTypes(cityId);
            if (response) {
                return response.data;
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionPostTicketTypes(context: MainContext, payload: { cityId: number, ticketTypes: ITicketType[] }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.saveTicketTypes(context.rootState.main.token, payload.cityId, payload.ticketTypes),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Mängelmelder-Kategorien erfolgreich aktualisiert', color: 'success' });

            return response.data;
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUsers(context: MainContext) {
        try {
            const response = await api.getUsers(context.rootState.main.token);
            if (response) {
                commitSetUsers(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUser(context: MainContext, payload: { id: number, user: IUserProfileUpdate }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateUser(context.rootState.main.token, payload.id, payload.user),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Benutzer erfolgreich aktualisiert', color: 'success' });
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createUser(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Benutzer erfolgreich erstellt', color: 'success' });
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteUser(context: MainContext, id: number) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteUser(context.rootState.main.token, id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Benutzer erfolgreich gelöscht.', color: 'success' });
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetCities(context: MainContext, hasAdminAccess: boolean) {
        try {
            if (hasAdminAccess) {
                const response = await api.getCitiesAdmin(context.rootState.main.token);
                if (response) {
                    commitSetCities(context, response.data);
                }
            } else {
                const response = await api.getCities(context.rootState.main.token);
                if (response) {
                    commitSetCities(context, response.data);
                }
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateCity(context: MainContext, payload: { id: number, city: ICityUpdate }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateCity(context.rootState.main.token, payload.id, payload.city),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetCity(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Stadt erfolgreich aktualisiert', color: 'success' });
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteCity(context: MainContext, cityId: number) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteCity(context.rootState.main.token, cityId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetCity(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Stadt erfolgreich gelöscht', color: 'success' });
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateCity(context: MainContext, payload: ICityCreate) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createCity(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetCity(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Stadt erfolgreich erstellt', color: 'success' });
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetCitySettings(context: MainContext, cityId: number) {
        try {
            const response = await api.getCitySettings(cityId);
            if (response) {
                return response.data;
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionPutCitySettings(context: MainContext, payload: { cityId: number, citySettings: ICitySettings }) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.saveCitySettings(context.rootState.main.token, payload.cityId, payload.citySettings),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Stadt-Einstellungen erfolgreich gespeichert.', color: 'success' });

            return response.data;
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetCityLegalTexts(context: MainContext, cityId: number) {
        try {
            const response = await api.getCityLegalTexts(cityId);
            if (response) {
                return response.data;
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionPutCityLegalTexts(context: MainContext, payload: { cityId: number, cityLegalTexts: ICityLegalTexts}) {
        const loadingNotification = { content: 'saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.saveCityLegalTexts(context.rootState.main.token, payload.cityId, payload.cityLegalTexts),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Rechtstexte erfolgreich gespeichert.', color: 'success' });

            return response.data;
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchSendPushNotification = dispatch(actions.actionSendPushNotification);

export const dispatchGetAppearances = dispatch(actions.actionGetAppearances);
export const dispatchCreateAppearances = dispatch(actions.actionCreateAppearances);
export const dispatchUpdateAppearances = dispatch(actions.actionUpdateAppearances);
export const dispatchDeleteAppearances = dispatch(actions.actionDeleteAppearances);

export const dispatchGetTreeNodes = dispatch(actions.actionGetTreeNodes);
export const dispatchSaveTreeNode = dispatch(actions.actionSaveTreeNode);

export const dispatchGetHomePage = dispatch(actions.actionGetHomePage);
export const dispatchPostHomePage = dispatch(actions.actionPostHomePage);

export const dispatchGetTickets = dispatch(actions.actionGetTickets);
export const dispatchGetOneTicket = dispatch(actions.actionGetOneTicket);
export const dispatchUpdateTicket = dispatch(actions.actionUpdateTicket);
export const dispatchDeleteTicket = dispatch(actions.actionDeleteTicket);

export const dispatchGetTicketTypes = dispatch(actions.actionGetTicketTypes);
export const dispatchPostTicketTypes = dispatch(actions.actionPostTicketTypes);

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchDeleteUser = dispatch(actions.actionDeleteUser);

export const dispatchCreateCity = dispatch(actions.actionCreateCity);
export const dispatchGetCities = dispatch(actions.actionGetCities);
export const dispatchUpdateCity = dispatch(actions.actionUpdateCity);
export const dispatchDeleteCity = dispatch(actions.actionDeleteCity);

export const dispatchGetCitySettings = dispatch(actions.actionGetCitySettings);
export const dispatchPutCitySettings = dispatch(actions.actionPutCitySettings);
export const dispatchGetCityLegalTexts = dispatch(actions.actionGetCityLegalTexts);
export const dispatchPutCityLegalTexts = dispatch(actions.actionPutCityLegalTexts);
