


















































































































































































































import { Vue, Component } from "vue-property-decorator";

import { apiUrl, appName } from "@/env";
import {
  readCityId,
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
  readHasCityAccess,
  readHasCityAdminAccess,
} from "@/store/main/getters";
import {
  commitSetDashboardShowDrawer,
  commitSetDashboardMiniDrawer,
} from "@/store/main/mutations";
import { dispatchUserLogOut } from "@/store/main/actions";
import { readAdminOneCity } from "@/store/admin/getters";
import { ICity } from "@/interfaces";
import { dispatchGetCities } from "@/store/admin/actions";

const routeGuardMain = async (to: any, from: any, next: any) => {
  if (to.path === "/main") {
    next("/main/dashboard");
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {
  public appName = appName;
  private city?: ICity;

  public beforeRouteEnter(to: any, from: any, next: any) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to: any, from: any, next: any) {
    routeGuardMain(to, from, next);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store)
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store)
    );
  }

  public get displayCityImage() {
    if (!this.hasCityAccess) return false;

    const cityId = readCityId(this.$store);
    if (!this.city) this.city = readAdminOneCity(this.$store)(cityId);
    return this.city?.image_filename != null;
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public get hasCityAccess() {
    return readHasCityAccess(this.$store);
  }

  public get hasCityAdminAccess() {
    return readHasCityAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }

  public async mounted() {
    if (!this.hasCityAccess) return;

    await dispatchGetCities(this.$store, this.hasAdminAccess ?? false);
  }

  public getCityImage() {
    return `${apiUrl}/api/v1${this.city?.image_filename}`;
  }
}
