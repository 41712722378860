import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    hasAdminAccess: (state: MainState) => {
        return (state.parsedToken && state.parsedToken.is_superuser);
    },
    hasCityAccess: (state: MainState) => {
        return (
            state.parsedToken &&
            !state.parsedToken.is_superuser &&
            (state.parsedToken.city_id ?? -1) >= 0
        );
    },
    hasCityAdminAccess: (state: MainState) => {
        return (
            state.parsedToken &&
            !state.parsedToken.is_superuser &&
            state.parsedToken.is_city_admin
        );
    },
    cityId: (state: MainState) => {
        return state.parsedToken?.city_id ?? 0;
    },
    loginError: (state: MainState) => state.logInError,
    dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
    dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
    userProfile: (state: MainState) => state.userProfile,
    token: (state: MainState) => state.token,
    parsedToken: (state: MainState) => state.parsedToken,
    isLoggedIn: (state: MainState) => state.isLoggedIn,
    firstNotification: (state: MainState) => state.notifications.length > 0 && state.notifications[0],
};

const { read } = getStoreAccessors<MainState, State>('');

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readHasCityAccess = read(getters.hasCityAccess);
export const readHasCityAdminAccess = read(getters.hasCityAdminAccess);
export const readCityId = read(getters.cityId);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readParsedToken = read(getters.parsedToken);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
