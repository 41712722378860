import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1e1f21',
                //secondary: '#b0bec5',
                //accent: '#8c9eff',
                //error: '#b71c1c',
            },
        },
    },
});
