import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate, ICity, ICityUpdate, ICityCreate, ITicketType, ITicketUpdate, IHomePageRowsCreate, IAppearanceTreeNodeCreate, IAppearanceCreate, ICitySettings, ICityLegalTexts } from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

function authHeadersFormData(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    }
  }
}

export const api = {
  async getCityToken(token: string, cityId: number) {
    return axios.post(`${apiUrl}/api/v1/login/city-access-token/${cityId}`, undefined, authHeaders(token));
  },
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async deleteUser(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/users/${id}`, authHeaders(token));
  },
  async getCitiesAdmin(token: string) {
    return axios.get<ICity[]>(`${apiUrl}/api/v1/cities/admin`, authHeaders(token));
  },
  async getCities(token: string) {
    return axios.get<ICity[]>(`${apiUrl}/api/v1/cities/`, authHeaders(token));
  },
  async updateCity(token: string, cityId: number, data: ICityUpdate) {
    const bodyFormData = new FormData();
    if (data.name)
      bodyFormData.append('name', data.name);
    if (data.is_active !== null)
      bodyFormData.append('is_active', `${data.is_active}`);
    if (data.latitude && data.longitude) {
      bodyFormData.append('latitude', `${data.latitude}`);
      bodyFormData.append('longitude', `${data.longitude}`);
    }
    if (data.radius)
      bodyFormData.append('radius', `${data.radius}`);
    if (data.one_signal_app_id && data.one_signal_api_key && data.one_signal_api_key_expiration_date) {
      bodyFormData.append('one_signal_app_id', `${data.one_signal_app_id}`);
      bodyFormData.append('one_signal_api_key', `${data.one_signal_api_key}`);
      bodyFormData.append('one_signal_api_key_expiration_date', `${data.one_signal_api_key_expiration_date}`);
    }
    if (data.coat_of_arms)
      bodyFormData.append('coat_of_arms', data.coat_of_arms, data.coat_of_arms.name);

    return axios.put(`${apiUrl}/api/v1/cities/${cityId}`, bodyFormData, authHeadersFormData(token));
  },
  async createCity(token: string, data: ICityCreate) {
    const bodyFormData = new FormData();
    bodyFormData.append('name', data.name);
    bodyFormData.append('is_active', `${data.is_active}`);
    bodyFormData.append('latitude', `${data.latitude}`);
    bodyFormData.append('longitude', `${data.longitude}`);
    bodyFormData.append('radius', `${data.radius}`);
    if (data.one_signal_app_id && data.one_signal_api_key && data.one_signal_api_key_expiration_date) {
      bodyFormData.append('one_signal_app_id', `${data.one_signal_app_id}`);
      bodyFormData.append('one_signal_api_key', `${data.one_signal_api_key}`);
      bodyFormData.append('one_signal_api_key_expiration_date', `${data.one_signal_api_key_expiration_date}`);
    }
    if (data.coat_of_arms)
      bodyFormData.append('coat_of_arms', data.coat_of_arms, "file");

    return axios.post(`${apiUrl}/api/v1/cities/`, bodyFormData, authHeadersFormData(token));
  },
  async deleteCity(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/cities/${id}`, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getTicketTypes(cityId: number) {
    return axios.get(`${apiUrl}/api/v1/cities/${cityId}/ticket-types/`);
  },
  async saveTicketTypes(token: string, cityId: number, ticketTypes: ITicketType[]) {
    return axios.post(`${apiUrl}/api/v1/cities/${cityId}/ticket-types/`, ticketTypes, authHeaders(token));
  },
  async getTickets(cityId: number, skip: number, limit: number) {
    return axios.get(`${apiUrl}/api/v1/cities/${cityId}/tickets/?skip=${skip}&limit=${limit}&include_inactive=true`);
  },
  async getTicket(cityId: number, ticketId: number) {
    return axios.get(`${apiUrl}/api/v1/cities/${cityId}/tickets/${ticketId}`);
  },
  async saveTicket(token: string, cityId: number, ticketId: number, data: ITicketUpdate) {
    // TODO: implement
    const bodyFormData = new FormData();
    if (data.title)
      bodyFormData.append('title', data.title);
    if (data.description)
      bodyFormData.append('description', data.description);
    if (data.city_feedback)
      bodyFormData.append('city_feedback', data.city_feedback);
    if (data.file)
      bodyFormData.append('file', data.file);
    if (data.latitude)
      bodyFormData.append('latitude', data.latitude.toString());
    if (data.longitude)
      bodyFormData.append('longitude', data.longitude.toString());
    if (data.location_text)
      bodyFormData.append('location_text', data.location_text);
    if (data.reporter_name)
      bodyFormData.append('reporter_name', data.reporter_name);
    if (data.reporter_email)
      bodyFormData.append('reporter_email', data.reporter_email);
    if (data.ticket_sub_type_id)
      bodyFormData.append('ticket_sub_type_id', data.ticket_sub_type_id.toString());
    console.log(data.is_active);
    console.log(data.is_active != undefined);
    if (data.is_active != undefined)
      bodyFormData.append('is_active', data.is_active.toString());
    if (data.file)
      bodyFormData.append('file', data.file, 'file');

    return axios.put(`${apiUrl}/api/v1/cities/${cityId}/tickets/${ticketId}`, bodyFormData, authHeadersFormData(token));
  },
  async deleteTicket(token: string, cityId: number, ticketId: number) {
    return axios.delete(`${apiUrl}/api/v1/cities/${cityId}/tickets/${ticketId}`, authHeaders(token));
  },
  async getHomePage(cityId: number) {
    return axios.get(`${apiUrl}/api/v1/cities/${cityId}/home-page/`);
  },
  async saveHomePage(token: string, cityId: number, homePage: IHomePageRowsCreate, files: { name: string, file: File }[]) {
    const bodyFormData = new FormData();
    console.log(homePage);
    bodyFormData.append('data', JSON.stringify(homePage));
    files.forEach(value => bodyFormData.append('files', value.file, value.name));

    return axios.post(`${apiUrl}/api/v1/cities/${cityId}/home-page/`, bodyFormData, authHeadersFormData(token));
  },
  async getTreeNodes(cityId: number) {
    return axios.get(`${apiUrl}/api/v1/cities/${cityId}/appearances/tree`);
  },
  async saveTreeNode(token: string, cityId: number, node: IAppearanceTreeNodeCreate[]) {
    return axios.post(`${apiUrl}/api/v1/cities/${cityId}/appearances/tree`, node, authHeaders(token));
  },
  async getAppearances(cityId: number, subNodeId: number) {
    return axios.get(`${apiUrl}/api/v1/cities/${cityId}/appearances/${subNodeId}`);
  },
  async createAppearance(token: string, cityId: number, appearance: IAppearanceCreate, files: { name: string, file: File }[]) {
    const bodyFormData = new FormData();
    console.log(JSON.stringify(appearance));
    bodyFormData.append('data', JSON.stringify(appearance));
    files.forEach(value => bodyFormData.append('files', value.file, value.name));

    return axios.post(`${apiUrl}/api/v1/cities/${cityId}/appearances/`, bodyFormData, authHeadersFormData(token));
  },
  async updateAppearance(token: string, cityId: number, appearanceId: number, appearance: IAppearanceCreate, files: { name: string, file: File }[]) {
    const bodyFormData = new FormData();
    console.log(JSON.stringify(appearance));
    bodyFormData.append('data', JSON.stringify(appearance));
    files.forEach(value => bodyFormData.append('files', value.file, value.name));

    return axios.put(`${apiUrl}/api/v1/cities/${cityId}/appearances/${appearanceId}`, bodyFormData, authHeadersFormData(token));
  },
  async deleteAppearance(token: string, cityId: number, appearanceId: number) {
    return axios.delete(`${apiUrl}/api/v1/cities/${cityId}/appearances/${appearanceId}`, authHeaders(token));
  },
  async sendPushNotification(token: string, cityId: number, appearanceId: number, isSystemNotification: boolean) {
    return axios.post(
      `${apiUrl}/api/v1/cities/${cityId}/push-notifications/?appearance_id=${appearanceId}&is_system_notification=${isSystemNotification}`,
      null,
      authHeaders(token),
    );
  },
  async getCitySettings(cityId: number) {
    return axios.get(`${apiUrl}/api/v1/cities/${cityId}/settings`);
  },
  async saveCitySettings(token: string, cityId: number, settings: ICitySettings) {
    return axios.put(`${apiUrl}/api/v1/cities/${cityId}/settings`, settings, authHeaders(token));
  },
  async getCityLegalTexts(cityId: number) {
    return axios.get(`${apiUrl}/api/v1/cities/${cityId}/legal-texts`);
  },
  async saveCityLegalTexts(token: string, cityId: number, legalTexts: ICityLegalTexts) {
    return axios.put(`${apiUrl}/api/v1/cities/${cityId}/legal-texts`, legalTexts, authHeaders(token));
  },
};
