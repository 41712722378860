import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login.vue'
import Main from '@/views/main/Main.vue'
import RouterComponent from '@/components/RouterComponent.vue';

Vue.use(Router)

export default new Router({
  routes: [{
    path: '/',
    component: () => import(/* webpackChunkName: "start" */ '@/views/main/Start.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login
      },
      {
        path: 'recover-password',
        component: () => import(/* webpackChunkName: "recover-password" */ '@/views/PasswordRecovery.vue'),
      },
      {
        path: 'reset-password',
        component: () => import(/* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'),
      },
      {
        path: 'main',
        name: 'main',
        component: Main,
        children: [
          {
            path: 'dashboard',
            component: () => import(/* webpackChunkName: "main-dashboard" */ '@/views/main/Dashboard.vue'),
          },
          {
            path: 'profile',
            component: RouterComponent,
            redirect: 'profile/view',
            children: [
              {
                path: 'view',
                component: () => import(
                  /* webpackChunkName: "main-profile" */ '@/views/main/profile/UserProfile.vue'),
              },
              {
                path: 'edit',
                component: () => import(
                  /* webpackChunkName: "main-profile-edit" */ '@/views/main/profile/UserProfileEdit.vue'),
              },
              {
                path: 'password',
                component: () => import(
                  /* webpackChunkName: "main-profile-password" */ '@/views/main/profile/UserProfileEditPassword.vue'),
              },
            ],
          },
          {
            path: 'admin',
            component: () => import(/* webpackChunkName: "main-admin" */ '@/views/main/admin/Admin.vue'),
            redirect: 'admin/users/all',
            children: [
              {
                path: 'users',
                redirect: 'users/all',
              },
              {
                path: 'users/all',
                name: 'main-admin-users-all',
                component: () => import(
                  /* webpackChunkName: "main-admin-users" */ '@/views/main/admin/user/AdminUsers.vue'),
              },
              {
                path: 'users/edit/:id',
                name: 'main-admin-users-edit',
                component: () => import(
                  /* webpackChunkName: "main-admin-users-edit" */ '@/views/main/admin/user/EditUser.vue'),
              },
              {
                path: 'users/create',
                name: 'main-admin-users-create',
                component: () => import(
                  /* webpackChunkName: "main-admin-users-create" */ '@/views/main/admin/user/CreateUser.vue'),
              },
              {
                path: 'cities',
                redirect: 'cities/all',
              },
              {
                path: 'cities/all',
                name: 'main-admin-cities-all',
                component: () => import(
                  /* webpackChunkName: "main-admin-cities" */ '@/views/main/admin/cities/AdminCities.vue'),
              },
              {
                path: 'cities/edit/:id',
                name: 'main-admin-cities-edit',
                component: () => import(
                  /* webpackChunkName: "main-admin-cities-edit" */ '@/views/main/admin/cities/EditCity.vue'),
              },
              {
                path: 'cities/create',
                name: 'main-admin-cities-create',
                component: () => import(
                  /* webpackChunkName: "main-admin-cities-create" */ '@/views/main/admin/cities/CreateCity.vue'),
              },
            ],
          },
          {
            path: 'city',
            component: RouterComponent,
            redirect: 'city/settings',
            children: [
              {
                path: 'settings',
                component: () => import(/* webpackChunkName: "main-city-settings" */ '@/views/main/city/CitySettings.vue'),
              },
              {
                path: 'users',
                redirect: 'users/all',
              },
              {
                path: 'users/all',
                component: () => import(/* webpackChunkName: "main-city-users-all" */ '@/views/main/city/CityUsers.vue'),
              },
              {
                path: 'users/edit/:id',
                name: 'main-city-users-edit',
                component: () => import(
                  /* webpackChunkName: "main-admin-users-edit" */ '@/views/main/city/EditUser.vue'),
              },
              {
                path: 'users/create',
                name: 'main-city-users-create',
                component: () => import(
                  /* webpackChunkName: "main-admin-users-create" */ '@/views/main/city/CreateUser.vue'),
              },
              {
                path: 'home-page',
                component: () => import(/* webpackChunkName: "main-city-home-page" */ '@/views/main/city/CityHomePage.vue'),
              },
              {
                path: 'appearances',
                component: () => import(/* webpackChunkName: "main-city-appearances" */ '@/views/main/city/CityAppearances.vue'),
              },
              {
                path: 'tickets',
                redirect: 'tickets/all',
              },
              {
                path: 'tickets/all',
                component: () => import(/* webpackChunkName: "main-city-tickets-all" */ '@/views/main/city/CityTickets.vue'),
              },
              {
                path: 'tickets/edit/:id',
                name: 'main-city-tickets-edit',
                component: () => import(/* webpackChunkName: "main-city-tickets-edit" */ '@/views/main/city/EditTicket.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/*', redirect: '/',
  },
  ]
})